<template>
  <div class="aside-wrapper">
    <div class="aside-item" @click="scrollTop">
      <img @mouseenter="showPop=true" @mouseleave="showPop=false" src="~common/images/icon_aside_1.png" class="aside-icon">
      <!-- <p class="desc">顶部</p> -->

      <img v-show="showPop" src="~common/images/icon_aside_1_pop.png" class="aside-pop" />
    </div>
    <!-- <div class="aside-item" onclick="qimoChatClick()">
      <img src="~common/images/icon_aside_2.png" class="aside-icon">
      <p class="desc">在线咨询</p>
    </div> -->
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    data() {
      return {
        showPop: false,
      }
    },
    methods: {
      scrollTop() {
        this.$emit('scrollTop')
      }
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .aside-wrapper
    position fixed
    right 0rem
    width .36458rem
    top: 50%;
    transform: translateY(-50%);
    .aside-item
      width 100%
      box-sizing border-box
      text-align center
      color #fff
      cursor pointer
      &:last-child
        border-bottom none
      .desc
        font-size .07292rem
      .aside-icon
        width .36458rem
        top: 50%;
        right: 0;
        transform: translateY(-50%)
        position: absolute;
      .aside-pop
        position relative
        width 1.5rem
        right 1.35rem
        top: 0;
</style>
