<template>
  <div class="index-wrapper" ref="index" >
    <div class="index-inner">
      <p-header></p-header>
      <div class="top-container">
        <img src="~common/images/index_top.png" alt="" class="top-img" />
        <div class="top-title">
          <!-- <p class="title">快速搞定<br/><font style="color: #08BB57;">包装设计稿件</font>管理</p>
          <p class="desc">帮助企业提升包装设计稿流转协作<br/>效率，供应链管理“稿真无忧<font >®</font>”</p> -->
          <img src="~common/images/top_btn.png" alt="" class="top-btn" @click="tapDetail"/>
        </div>

        <div class="top-title2">解决产品设计<font style="color: #08BB57;">稿件管理痛点</font></div>
        
        <div class="tip-box">
            <vue-seamless-scroll v-if="isShow" :data="list" style="width:100%" :class-option="defaultOption"  >
              <div class="tips">
                <div class="tip-item" v-for="item in list" :key="item">
                  <img :src="item" alt="" style="height: 0.57rem" />
                </div>
              </div>
            </vue-seamless-scroll>
            
            <vue-seamless-scroll v-if="isShow" :data="list2" style="width:100%" :class-option="defaultOption2"  >
              <div class="tips">
                <div class="tip-item" v-for="item in list2" :key="item">
                  <img :src="item" alt="" style="height: 0.57rem" />
                </div>
              </div>
            </vue-seamless-scroll>
        </div>
      </div>
      
      <div class="subtitle">重新定义<font style="color: #08BB57;">包装供应链管理</font></div>
      <div class="subtitle2">用平台解放人力，打造更有创新力、执行力、判断力的团队。</div>
      <div class="content">
        <img src="~common/images/index_img_1.png" alt="" class="content-img" />
        <img src="~common/images/index_img_2.png" alt="" class="content-img" />
        <img src="~common/images/index_img_3.png" alt="" class="content-img" />
        <img src="~common/images/index_img_4.png" alt="" class="content-img" />
      </div>
      <div class="subtitle-btn"><img class="subtitle-btn-img" @click="tapDetail" src="~common/images/top_btn.png" alt="" /></div>
      

      <div class="video-box" @click="tapVideo">
        <div class="video-box-title">流畅的操作体验，更多功能尽在<font style="color: #08BB57;">稿真无忧<div style="font-size: 0.17rem;position: absolute;display: inline;">®</div></font></div>
        <img src="~common/images/video_poster.png" alt="" style="width: 5rem" />
        <img src="~common/images/video_btn.png" alt="" class="video-btn" />
      </div>

      <div style="position: relative;">
        <div><img src="~common/images/banner_bg.png" width="100%" alt="" /></div>
        <div class="bannertitle">效率提升<font style="font-family: serif;">，</font>“稿”真无忧</div>
        <div class="bannersubtitle">通过降低时间、人力成本，控制生产、机会成本，提升包装供应链内外部管理效率，<br/>把控包装供应链品质标准，降低包装供应链损耗。</div>
        
        <div style="position: absolute; top: 0;height: 100%;width: 100%">
          <slider>
            <div class="banner"><img src="~common/images/banner_1.png" class="bannerImg" alt=""></div>
            <div class="banner"><img src="~common/images/banner_2.png" class="bannerImg" alt=""></div>
            <div class="banner"><img src="~common/images/banner_3.png" class="bannerImg" alt=""></div>
          </slider>
        </div>
        <div><img class="usebtn" @click="tapDetail" src="~common/images/banner_btn.png" width="100%" alt="" /></div>
      </div>
      

      <p-footer></p-footer>
      <aside-bar ></aside-bar>
    </div>
    <div class="videoModeil" v-show="isShowVideo">
      <div class="videoDiv">
        <video 
          id="video"
          controls 
          playsinline 
          webkit-playsinline="webkit-playsinline"
          class="video" preload="auto"
                   poster="https://vod.greatdata.com.cn/snapshot/37fd4e607cf671ed80826723a78f010200005.jpg"
          src="https://vod.greatdata.com.cn/37fd4e607cf671ed80826723a78f0102/41e7524d2f4c4f189d81a7281e317bae-5287d2089db37e62345123a1be272f8b.mp4"></video>
        <img src="~common/images/ic_close.png" class="close" @click="tapClose"/>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'
  import Slider from 'components/slide/slide'
  import asideBar from 'components/asideBar/asideBar'
  import vueSeamlessScroll from 'vue-seamless-scroll'

  export default {
    data() {
      return {
        isShow: true,
        isShowVideo: false,
        list: [require('common/images/tip1.png'),require('common/images/tip3.png'),require('common/images/tip2.png'),require('common/images/tip4.png'),require('common/images/tip5.png')],
        list2: [require('common/images/tip4.png'),require('common/images/tip2.png'),require('common/images/tip1.png'),require('common/images/tip5.png'),require('common/images/tip3.png')],
      }
    },
    computed: {
        defaultOption () {
            return {
                step: 1, // 数值越大速度滚动越快
                limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: false, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: false, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        },
        defaultOption2 () {
            return {
                step: 1.2, // 数值越大速度滚动越快
                limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: false, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: false, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    mounted() {
      window.onresize = () => {
        this.isShow = false
        setTimeout(() => {
          this.isShow= true
        }, 200);
      }
    },
    created() {
    },
    activated() {
    },
    methods: {
      tapUse() {
        // window._hmt.push(['_trackEvent', '首页底部', '免费试用']);
        // window.open('https://www.smtyxy.com/wxapp/index.html')
      },
      tapDetail(id) {
        this.$router.push({
          name: 'service'
        })
      },
      tapUseBanner() {
        // window._hmt.push(['_trackEvent', '首页轮播图', '免费试用']);
        // window.open('https://www.smtyxy.com/wxapp/index.html')
      },
      tapVideo() {
        this.isShowVideo = true
        document.getElementById("video").play()
      },
      tapClose() {
        document.getElementById("video").pause()
        this.isShowVideo = false
      }
    },
    components: {
      PHeader,
      Slider,
      asideBar,
      PFooter,
      vueSeamlessScroll
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .index-wrapper
    width 100%
    min-width 1270px
    background: #f0f2f5;
    height 100%
    .videoModeil
      position fixed
      top 0
      left 0
      right 0
      bottom 0
      background-color: rgba(9, 0, 0, 0.61);
      z-index 999
      .videoDiv
        position absolute
        top 50%
        width 1250px
        left 50%
        transform: translate(-50%, -50%)
        height 703px
        background: #FFFFFF
        padding 10px
        border-radius: 12px;
        .video
          width: 100%
          height: 100%;
        .close
          position absolute
          top -20px
          right -20px
          width: 40px
          height 40px
          cursor pointer
    .index-inner
      padding-top: 0.35rem;
      background: #f0f2f5;
      position relative
      .bannertitle
        font-size: 0.34rem;
        font-weight: bold;
        color: #FFFFFF;
        position absolute
        top: 0.49rem
        width: 100%;
        text-align: center;
      .bannersubtitle
        font-size: 0.14rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.25rem;
        position absolute
        top: 1rem
        width: 100%;
        text-align: center;
      .banner
        position relative
        width: 100%;
        height: 100%;
        .bannerImg
          width:6.38rem
          margin-top:1.9rem;
          position relative
          left: 50%
          transform: translateX(-50%)
      .usebtn
        cursor pointer
        position absolute
        left 1.85rem
        top 3.7rem
        width: 1rem;
    .top-container
      width: 100%
      position: relative
      background: #f0f2f5;
      .top-img
        width: 100%
      .top-title
        position: absolute
        top: 1.5rem;
        left: 1.45rem
        .title
          font-size: 0.32rem;
          font-weight: bold;
          color: #000000;
          line-height: 0.49rem;
        .desc
          font-size: 0.18rem;
          font-weight: 500;
          color: #000000;
          margin-top: 0.2rem
          line-height: 0.29rem;
        .top-btn
          margin-top: 1.86rem
          width: 1.17rem
          cursor pointer
          height: 0.36rem
      .top-title2
        position: absolute
        top: 6rem;
        width: 100%;
        text-align: center
        font-size: 0.34rem;
        font-weight: bold;
        color: #000000;
      .tip-box
        position: absolute
        top: 7rem;
        width: 100vw;
        overflow: hidden;
        .tips
          margin-top: 0.2rem
          width 12rem
          height: 0.57rem
          display: flex
          overflow: hidden;
          flex-wrap: nowrap
          .tip-item
            margin-right: 0.22rem;
            white-space: nowrap;
    .subtitle
      position relative
      font-size: 0.34rem;
      font-weight: bold;
      color: #000000;
      text-align: center
    .subtitle2
      position relative
      background: #f0f2f5;
      font-size: 0.13rem;
      font-weight: 500;
      color: #666666;
      margin-top: 0.36rem;
      text-align: center
    .subtitle-btn
      text-align: center
      padding: 0.68rem 0 0.57rem 0
      .subtitle-btn-img
        width 1.17rem;
        cursor pointer
    .content
      position relative
      background: #f0f2f5;
      margin-top: 0.7rem
      display: inline-flex;
      left: 50%
      transform: translateX(-50%)
      .content-img
        width: 1.67rem
        height: 1.49rem
        margin-left: 0.38rem
    .video-box
      position relative
      text-align: center
      background: #F6F6F6
      padding 0.86rem 0 0.37rem 0
      .video-box-title
        font-size: 0.34rem;
        font-weight: bold;
        color: #000000;
        text-align: center
        margin-bottom 0.29rem
      .video-btn
        position absolute
        width: 0.76rem
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%)
</style>
